.directions {
    color: darkgray;
    font-family: inherit;

}
#categories-importacao body {
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}

h6 {
    color: #008eff!important;
    font-family: inherit;
}

#info {
    font-family: inherit;
    color: #484848;
}

#display-none {
    display: none !important;
}

thead tr:nth-child(1) th{
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;
}