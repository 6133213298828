.z-index {
    z-index: -1000;
}

.color-h5 {
    color: rgb(52, 132, 201) !important;
    font-size: 15px;
}

.color-h6 {
    color: rgb(77, 77, 77) !important;
    font-size: 10px;
}

.balon {
    border-radius: 50%;
    border: solid 2px rgb(52, 132, 201);
    height: 250px;
    width: 250px;
    transition: 0.3s;
    background-color: #fff;
}

.btn-outline-primary {
    background-color: #fff;
    border-color: #254f78;
    color: #254f78;
    font-size: 15px;
}

.btn-outline-primary:hover {
    background-color: #abc434 !important;
    color: #fff !important;
}

.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:hover {
    outline: none;
    border-color: #254f78 !important;
    box-shadow: none !important;
}

.pt-balon {
    margin-top: 170px;
}

@media screen and (max-width: 992px) {
    .balon {
        margin-top: 15%;
    }

    .slideDown1 {
        all: unset!important;
    } 
    
    .slideDown2 {
        all: unset!important;
    }
     
    .slideDown3 {
        all: unset!important;
    } 
    
    .slideDown4 {
        all: unset!important;
    }
}