.video-card {
    font-family: Helvetica;
    color: rgb(46, 65, 125);
}

#videoCardDetail-css h4 {
    font-weight: bold;
    font-size: 25px;
}

#videoCardDetail-css h6 {
    font-size: 15px;
}

#videoCardDetail-css p {
    font-size: 19px;

}