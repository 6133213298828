@import './Animation.css';

.bg-blue-default{
    background-color: #253f78;
}

.border-color-blue-default{
    border-color: #253f78;
}

.text-color-blue-default{
    color: #253f78;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}