h1{
    font-size: 2em;
}

#login-css p{
    font-size: 15px;
}

.img-logo{
    width: 17%;
    margin-bottom: 30px;
}

.btn-outline-primary{
    background-color: #fff;
    font-size: 13px;
}

.btn-outline-primary:hover{
    background-color: #abc434;
}

.btn-outline-primary:focus, 
.btn-outline-primary:active,
.btn-outline-primary:hover{
    outline: none;
    border-color: transparent;
    box-shadow: none!important;
}