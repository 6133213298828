#loading {
    background: #eee;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    display: flex;
}

#loading span {
    align-self: center;
}