span {
    font-size: 70%;

}

#footer {
    position: sticky;
    padding: 10px 100px 0px 10px;
    bottom: 0;
    float: right;
    text-align: center;
    width: 100%;
    height: 250px;
    background: transparent;
}

#page-categories body {
    overflow: hidden;
}

#page-categories h5{
    font-size: 60%;
}

#page-categories .card-effect-container {
    width: 14.28%;
    float: left;
    padding: 10px;
}

#page-categories .input-pesquisar {
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 7%), 0 6px 20px 0 rgb(0 0 0 / 3%);
}

#page-categories .btn-outline-primary {
    background-color: #fff;
    border-color: #254f78;
    color: #254f78;
    font-size: 15px;
}

#page-categories .btn-outline-primary:hover {
    background-color: #abc434 !important;
    color: #fff !important;
}

#page-categories .btn-outline-primary:focus,
#page-categories .btn-outline-primary:active,
#page-categories .btn-outline-primary:hover {
    outline: none;
    border-color: #254f78 !important;
    box-shadow: none !important;
}

#page-categories .br {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    transition: 0.3s;
}

#page-categories .br:hover {
    transform: translateY(-5px);
}

#page-categories .text-color-green {
    color: #abc434;
}


@media screen and (max-width: 992px) {

    #page-categories .overflow-hidden {
        overflow: initial;
    }

    #page-categories .slideUp1 {
        all: unset;
    }

    #page-categories .card-effect-container {
        display: block !important;
        width: 100%;
        padding-bottom: 15px;
    }

    #page-categories .height {
        height: auto !important;
    }

    #page-categories .fixed-bottom {
        all: unset;
    }

    body {
        overflow: initial;
    }
}