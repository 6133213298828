.video-card {
    font-family: Helvetica;
    color: rgb(46, 65, 125);
}

h4 {
    font-weight: bold;
    font-size: 20px;
}

h6 {
    font-size: 15px;
}

p {
    font-size: 19px;

}