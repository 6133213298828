span {
    font-size: 70%;

}

.sidebar {
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: 48px 0 0;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}



/* .scroll-div {
    width: 100%;
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
} */


.height {
    height: auto;
}

.input-pesquisar {
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 7%), 0 6px 20px 0 rgb(0 0 0 / 3%);
}

.btn-outline-primary {
    background-color: #fff;
    border-color: #254f78;
    color: #254f78;
    font-size: 15px;
}

.btn-outline-primary:hover {
    background-color: #abc434 !important;
    color: #fff !important;
}

.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:hover {
    outline: none;
    border-color: #254f78 !important;
    box-shadow: none !important;
}

.br {
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

.loading-video-card{
    margin-top: 15%;
}

.text-color-green {
    color: #abc434;
}


@media screen and (max-width: 992px) {
    .margin-moba{
        margin-top: 5%;
    }
    .sidebar-sticky{
        height: auto;
        margin-bottom: 15%;
    }
}